import * as actions from './actionTypes';

export default function reducer(state, action)
{
  const newState = {...state};
  
  switch (action.type)
  {
    case actions.LANGUAGE_SELECT:
      const locale = action.language.locale;
      newState.locale = locale;
      newState.lang = locale.split('-')[0];
      newState.languages.forEach(item => item.selected = item.locale === locale);
      localStorage.setItem('locale', locale);
      return newState;
    
    case actions.AUTH_CHANGE:
      newState.user = {...newState.user, ...action.user};
      localStorage.setItem('user', JSON.stringify(action.user));
      return newState;
    
    case actions.LOADER:
      newState.loader = {
        isOpen: action.isOpen,
        content: action.content,
        percent: action.percent
      };
      return newState;
    
    case actions.ALERT_SHOW:
      newState.alert = {
        isOpen: true,
        content: action.content,
        vars: action.vars,
        allowClose: action.allowClose
      };
      return newState;
    
    case actions.ALERT_HIDE:
      newState.alert = {
        isOpen: false,
        content: '',
        vars: []
      };
      return newState;
    
    case actions.COPY_TO_BUFFER:
      newState.buffer = action.data;
      return newState;
    
    case actions.LOAD_TARIFFS:
      newState.tariffs = action.payload;
      return newState;
      
    case actions.SET_USER_MATERIALS:
      newState.userMaterials = {...newState.userMaterials, ...action.payload};
      return newState;
    
    case actions.SET_GLOBAL_MATERIALS:
      newState.globalMaterials = {...newState.globalMaterials, ...action.payload};
      return newState;
  
    case actions.CLOSE_BANNER:
      newState[action.name] = '1';
      localStorage.setItem(action.name, '1');
      return newState;
    
    default:
      return state;
  }
}
