import {takeEvery, put, call} from 'redux-saga/effects';
import {REQUEST_LOAD_TARIFFS} from "./actionTypes";
import {backend} from "../backend/backend";
import {loadTariffs} from "./actions";

export function* sagaWatcher()
{
  yield takeEvery(REQUEST_LOAD_TARIFFS, requestLoadTariffWorker);
}

function* requestLoadTariffWorker({lang})
{
  try
  {
    const payload = yield call(fetchTariffs, lang);
    yield put(loadTariffs(payload));
  } catch (e)
  {
  }
}

async function fetchTariffs(lang)
{
  const result = await backend('getTariffs', {lang});
  
  if (result.type !== 'data')
  {
    throw new Error();
  }
  
  return result.data;
}
