export const LANGUAGE_SELECT = 'LANGUAGE_SELECT';
export const AUTH_CHANGE = 'AUTH_CHANGE';
export const LOADER = 'LOADER';
export const ALERT_SHOW = 'ALERT_SHOW';
export const ALERT_HIDE = 'ALERT_HIDE';
export const COPY_TO_BUFFER = 'COPY_TO_BUFFER';
export const REQUEST_LOAD_TARIFFS = 'PREPARE_LOAD_TARIFFS';
export const LOAD_TARIFFS = 'LOAD_TARIFFS';
export const SET_USER_MATERIALS = 'SET_USER_MATERIALS';
export const SET_GLOBAL_MATERIALS = 'SET_GLOBAL_MATERIALS';
export const CLOSE_BANNER = 'CLOSE_BANNER';
