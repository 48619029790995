import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {closeBanner} from '../../store/actions';
import {FormattedMessage} from 'react-intl';
import Button from '../html/Button';

const Banner = ({name}) =>
{
  const stateValue = useSelector(state => state[name]);
  const visibility = stateValue !== '1';
  
  const dispatch = useDispatch();
  
  if (!visibility)
  {
    return null;
  }
  
  const close = () =>
  {
    dispatch(closeBanner(name));
  };
  
  return (
    <div className="banner">
      <div className="banner__text"><FormattedMessage id={`banner_${name}`}/></div>
      <div className="banner__button">
        <Button caption={<FormattedMessage id="phrase_ok"/>}
                styleModifiers={['bright']}
                onClick={() => close()}/>
      </div>
    </div>
  );
};

export default Banner;