import React from 'react';
import {useIntl} from 'react-intl';

export interface HtmlProps {
  value: any,
  vars?: string[],
  styles?: string[]
}

const Html = ({
  value,
  vars,
  styles
}: HtmlProps) =>
{
  const intl = useIntl();
  
  const stylesCur: string[] = [];
  
  if (styles)
  {
    styles.forEach(item => stylesCur.push(item));
  }
  
  const isPropsId = value.props?.id !== undefined;
  const isHtml = typeof value === 'string' || isPropsId;
  
  let valueCur = isPropsId ? intl.formatMessage({id: value.props.id}) : value;
  
  if (vars && typeof valueCur === 'string')
  {
    vars.forEach(item => valueCur = (valueCur as string).replace(`{${item[0]}}`, item[1]));
  }
  
  const stylesResult = stylesCur.length > 0 ? stylesCur.join(' ') : 'span';
  
  return (
    isHtml ?
      <div className={stylesResult} dangerouslySetInnerHTML={{__html: valueCur}}/> :
      <div className={stylesResult}>{valueCur}</div>
  );
};

export default Html;
