import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import './index.css';
import './scss/main.scss';
import App from './App';
import store from './store/store';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<Provider store={store}><App/></Provider>);
