import {useEffect} from 'react';

export function useOutsideClick(refArray, callback)
{
  useEffect(() =>
  {
    function handleClickOutside(event)
    {
      if (refArray.find(ref => ref.current && !ref.current.contains(event.target) && !ref.current.parentNode?.contains(event.target)) !== undefined)
        callback();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () =>
    {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refArray, callback]);
}