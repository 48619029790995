import React from 'react';
import LinkText from './LinkText';

const IconLink = ({
  alias,
  url,
  title,
  styles
}) =>
{
  const stylesCur = ['iconButton', `iconButton_${alias}`];
  
  if (styles !== undefined)
    styles.forEach(item =>
    {
      stylesCur.push(item);
    });
  
  return (
    <LinkText url={url} alias={alias} title={title} styles={stylesCur} />
  );
};

export default IconLink;
