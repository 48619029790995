import React from 'react';
import {useSelector} from 'react-redux';

const LinkText = ({
  url,
  target,
  onClick,
  title,
  styles,
  children
}) =>
{
  const pages = useSelector(state => state.pages);
  
  const urlCur = pages.get(url)?.url ?? url;
  
  const stylesCur = [];
  
  if (styles !== undefined)
  {
    styles.forEach(item =>
    {
      stylesCur.push(item);
    });
  }
  
  if (!urlCur)
  {
    stylesCur.push('notActiveLink');
  }
  
  const className = stylesCur.join(' ');
  
  const isExternal = [':', '.'].find(item => urlCur.indexOf(item) !== -1) !== undefined;
  
  return (
    urlCur ?
      <a className={className}
         href={urlCur}
         target={target ?? (isExternal ? '_blank' : '_self')}
         rel={isExternal ? "noreferrer" : ''}
         onClick={onClick}
         title={title}>{children}</a> :
      <div className={className}>{children}</div>
  );
};

export default LinkText;
