import React, {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import IconButton from '../html/IconButton';
import LinkText from '../html/LinkText';
import {useOutsideClick} from '../../hooks/useOutsideClick';
import {isUrlSelected} from '../../common/common';

const Menu = () =>
{
  const pages = useSelector(state => state.pages);
  
  const intl = useIntl();
  
  const [show, setShow] = useState(false);
  
  const toggle = () =>
  {
    setShow(!show);
  };
  
  const menuButtonRef = useRef(null);
  const menuWindowRef = useRef(null);
  const menuOutsideClick = useCallback(() => setShow(false), []);
  useOutsideClick([menuButtonRef, menuWindowRef], menuOutsideClick);
  
  return (
    <div className="menu">
      <div ref={menuButtonRef}>
        <IconButton alias="menu"
                    onClick={() => toggle()}
                    title={intl.formatMessage({id: 'phrase_menu'})}
                    styles={['menu__button']}/>
      </div>
      {
        show &&
        <div ref={menuWindowRef} className="menuWindow">
          <IconButton alias="close" onClick={() => setShow(false)}
                      title={intl.formatMessage({id: 'phrase_close'})}
                      styles={['menuWindow__close']}/>
          <div className="menuWindow__buttons">
            {
              Array.from(pages.values()).filter(page => page.isMenu === true).map(page =>
              {
                let url = page.url;
                const buttonStyles = ['menuWindow__button'];
                
                if (isUrlSelected(url))
                {
                  url = '';
                  buttonStyles.push('menuWindow__button_selected');
                }
                
                return (
                  <LinkText url={url}
                            onClick={() => setShow(false)}
                            styles={buttonStyles}
                            key={page.alias}><FormattedMessage id={`menu_${page.alias}_caption`}/></LinkText>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  );
};

export default Menu;
