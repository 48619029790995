import React, {lazy, Suspense, useEffect} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useSelector, useDispatch} from 'react-redux';
import {alertHide} from './store/actions';
import {IntlProvider} from 'react-intl'
import ReactModal from 'react-modal';
import {LOCALES} from './i18n/locales'
import {messages} from './i18n/messages'
import Loader from './ui/html/Loader';
import Header from './ui/blocks/Header';
import Footer from './ui/blocks/Footer';
import Banner from './ui/blocks/Banner';
import ModalAlert from './ui/modal/ModalAlert';
import {site3dSite} from './backend/backend';

const PageMain = lazy(() => import('./pages/PageMain'));
const PageCases = lazy(() => import('./pages/PageCases'));
const PageReviews = lazy(() => import('./pages/PageReviews'));
const PageNews = lazy(() => import('./pages/PageNews'));
const PagePosts = lazy(() => import('./pages/PagePosts'));
const PageFaq = lazy(() => import('./pages/PageFaq'));
const PageAccount = lazy(() => import('./pages/PageAccount'));
const PageProfile = lazy(() => import('./pages/PageProfile'));
const PageOrders = lazy(() => import('./pages/PageOrders'));
const PageBuy = lazy(() => import('./pages/PageBuy'));
const PagePartners = lazy(() => import('./pages/PagePartners'));
const PageApi = lazy(() => import('./pages/PageApi'));
const PageSupport = lazy(() => import('./pages/PageSupport'));
const PageReg = lazy(() => import('./pages/PageReg'));
const PageAdmin = lazy(() => import('./pages/PageAdmin'));
const PageStart = lazy(() => import('./pages/PageStart'));
const PageError = lazy(() => import('./pages/PageError'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));

function App()
{
  const locale = useSelector(state => state.locale);
  const lang = useSelector(state => state.lang);
  const alert = useSelector(state => state.alert);
  
  const dispatch = useDispatch();
  
  const isAccountPage = window.location.pathname.indexOf('/account') !== -1;
  
  ReactModal.setAppElement('#root');
  
  const loadWidgets = () =>
  {
    const site3dLoader = window['site3dLoader'];
    
    if (!site3dLoader)
    {
      return;
    }
    
    const scriptElem = document.getElementById('site3d-configurator-load');
    
    if (!scriptElem)
    {
      return;
    }
    
    if (!site3dLoader.configuratorLoadScript)
    {
      site3dLoader.configuratorLoadScript = scriptElem;
    }
    
    site3dLoader.configuratorLoadScript.dataset.lang = lang;
    
    if (!isAccountPage && site3dLoader.configuratorCreate)
    {
      site3dLoader.configuratorCreate({needUpdate: true});
    }
  };
  
  /*useScript({
    url: `${site3dSite}/configurator/load.js`,
    params: {id: 'site3d-configurator-load'},
    data: {
      lang,
      additional: 'true',
      autostart: !isAccountPage
    }
  });*/
  
  useEffect(() =>
  {
    /* eslint-disable */
    
    loadWidgets();
  }, [lang]);
  
  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
      <BrowserRouter>
        <Loader/>
        <div className="wrapper">
          <Header/>
          <div className="page">
            <Routes>
              <Route path="/" element={<Suspense><PageMain/></Suspense>}/>
              <Route path="/en" element={<Suspense><PageMain langInit="en"/></Suspense>}/>
              <Route path="/:promo" element={<Suspense><PageMain/></Suspense>}/>
              <Route path="/:promo/en" element={<Suspense><PageMain langInit="en"/></Suspense>}/>
              <Route path="/cases" element={<Suspense><PageCases/></Suspense>}/>
              <Route path="/cases/en" element={<Suspense><PageCases langInit="en"/></Suspense>}/>
              <Route path="/cases/:id" element={<Suspense><PageCases/></Suspense>}/>
              <Route path="/cases/:id/en" element={<Suspense><PageCases langInit="en"/></Suspense>}/>
              <Route path="/reviews" element={<Suspense><PageReviews/></Suspense>}/>
              <Route path="/reviews/en" element={<Suspense><PageReviews langInit="en"/></Suspense>}/>
              <Route path="/news" element={<Suspense><PageNews/></Suspense>}/>
              <Route path="/news/en" element={<Suspense><PageNews langInit="en"/></Suspense>}/>
              <Route path="/news/:id" element={<Suspense><PageNews/></Suspense>}/>
              <Route path="/news/:id/en" element={<Suspense><PageNews langInit="en"/></Suspense>}/>
              <Route path="/posts" element={<Suspense><PagePosts/></Suspense>}/>
              <Route path="/posts/en" element={<Suspense><PagePosts langInit="en"/></Suspense>}/>
              <Route path="/posts/:alias" element={<Suspense><PagePosts/></Suspense>}/>
              <Route path="/posts/:alias/en" element={<Suspense><PagePosts langInit="en"/></Suspense>}/>
              <Route path="/faq" element={<Suspense><PageFaq/></Suspense>}/>
              <Route path="/faq/en" element={<Suspense><PageFaq langInit="en"/></Suspense>}/>
              <Route path="/account" element={<Suspense><PageAccount/></Suspense>}/>
              <Route path="/account/en" element={<Suspense><PageAccount langInit="en"/></Suspense>}/>
              <Route path="/account/:action" element={<Suspense><PageAccount/></Suspense>}/>
              <Route path="/account/:action/en" element={<Suspense><PageAccount langInit="en"/></Suspense>}/>
              <Route path="/account/:action/:param1" element={<Suspense><PageAccount/></Suspense>}/>
              <Route path="/account/:action/:param1/en" element={<Suspense><PageAccount langInit="en"/></Suspense>}/>
              <Route path="/account/:action/:param1/:param2" element={<Suspense><PageAccount/></Suspense>}/>
              <Route path="/account/:action/:param1/:param2/en"
                     element={<Suspense><PageAccount langInit="en"/></Suspense>}/>
              <Route path="/account/profile" element={<Suspense><PageProfile/></Suspense>}/>
              <Route path="/account/profile/en" element={<Suspense><PageProfile langInit="en"/></Suspense>}/>
              <Route path="/account/orders" element={<Suspense><PageOrders/></Suspense>}/>
              <Route path="/account/orders/en" element={<Suspense><PageOrders langInit="en"/></Suspense>}/>
              <Route path="/buy" element={<Suspense><PageBuy/></Suspense>}/>
              <Route path="/buy/en" element={<Suspense><PageBuy langInit="en"/></Suspense>}/>
              <Route path="/buy/:status" element={<Suspense><PageBuy/></Suspense>}/>
              <Route path="/buy/:status/en" element={<Suspense><PageBuy langInit="en"/></Suspense>}/>
              <Route path="/partners" element={<Suspense><PagePartners/></Suspense>}/>
              <Route path="/partners/en" element={<Suspense><PagePartners langInit="en"/></Suspense>}/>
              <Route path="/api" element={<Suspense><PageApi/></Suspense>}/>
              <Route path="/api/en" element={<Suspense><PageApi langInit="en"/></Suspense>}/>
              <Route path="/support" element={<Suspense><PageSupport/></Suspense>}/>
              <Route path="/support/en" element={<Suspense><PageSupport langInit="en"/></Suspense>}/>
              <Route path="/reg" element={<Suspense><PageReg/></Suspense>}/>
              <Route path="/reg/en" element={<Suspense><PageReg langInit="en"/></Suspense>}/>
              <Route path="/admin" element={<Suspense><PageAdmin/></Suspense>}/>
              <Route path="/admin/en" element={<Suspense><PageAdmin langInit="en"/></Suspense>}/>
              <Route path="/start/:source/:id" element={<Suspense><PageStart/></Suspense>}/>
              <Route path="/start/:source/:id/en" element={<Suspense><PageStart langInit="en"/></Suspense>}/>
              <Route path="/error" element={<Suspense><PageError/></Suspense>}/>
              <Route path="/error/en" element={<Suspense><PageError langInit="en"/></Suspense>}/>
              <Route path="*" element={<Suspense><PageNotFound/></Suspense>}/>
            </Routes>
          </div>
          <Footer/>
        </div>
        <Banner name="cookieAgree"/>
        <ModalAlert isOpen={alert.isOpen}
                    onClose={() => dispatch(alertHide())}
                    content={alert.content}
                    vars={alert.vars}
                    allowClose={alert.allowClose}/>
        <Helmet>
          <script
            id={'site3d-configurator-load'}
            src={`${site3dSite}/configurator/load.js?v=2.0`}
            data-lang={lang}
            data-additional={true}
            data-autostart={!isAccountPage}
            crossorigin="anonymous"
            defer={true}
          ></script>
        </Helmet>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App;