import * as actions from './actionTypes';

export const selectLanguage = language => ({
  type: actions.LANGUAGE_SELECT,
  language
});

export const authChange = user => ({
  type: actions.AUTH_CHANGE,
  user
});

export const loader = (isOpen, content = undefined, percent = undefined) => ({
  type: actions.LOADER,
  isOpen,
  content,
  percent
});

export const alertShow = (content, vars = [], allowClose = true) => ({
  type: actions.ALERT_SHOW,
  content,
  vars,
  allowClose
});

export const alertHide = () => ({
  type: actions.ALERT_HIDE
});

export const copyToBuffer = (data = {
  type: '',
  items: []
}) => ({
  type: actions.COPY_TO_BUFFER,
  data
});

export const requestLoadTariffs = lang => ({
  type: actions.REQUEST_LOAD_TARIFFS,
  lang
});

export const loadTariffs = payload => ({
  type: actions.LOAD_TARIFFS,
  payload
});

export const setUserMaterials = payload => ({
  type: actions.SET_USER_MATERIALS,
  payload
});

export const setGlobalMaterials = payload => ({
  type: actions.SET_GLOBAL_MATERIALS,
  payload
});

export const closeBanner = name => ({
  type: actions.CLOSE_BANNER,
  name
});
