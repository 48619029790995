import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectLanguage} from '../../store/actions';

const Language = () =>
{
  const languages = useSelector(state => state.languages);
  
  const dispatch = useDispatch();

  return (
    <div className="language">
      {
        Array.from(languages.values()).map(language =>
        {
          const buttonStyles = ['language__button'];
          if (language.selected)
            buttonStyles.push('language__button_selected')
          return (
            <button className={buttonStyles.join(' ')} onClick={() => dispatch(selectLanguage(language))} key={language.locale}>{language.caption}</button>
          )
        })
      }
    </div>
  );
};

export default Language;