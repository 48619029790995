import React from 'react';
import LinkText from './LinkText';

const LinkButton = ({caption, styles, styleModifiers, url}) =>
{
  const stylesCur = ['button'];
  
  if (styles !== undefined)
    styles.forEach(item =>
    {
      stylesCur.push(item);
    });
  
  if (styleModifiers !== undefined)
    styleModifiers.forEach(item => stylesCur.push(`button_${item}`));

  return (
    <LinkText url={url} styles={stylesCur}>{caption}</LinkText>
  );
};

export default LinkButton;
