export interface IWidget
{
  exportImage: Function
}

export interface IListItem
{
  id: number
}

export const MODEL_FORMATS = ['glb', 'gltf', 'usdz', 'fbx', 'stl', 'obj', '3ds', '3dm', '3mf', 'dxf', 'iges', 'igs', 'step', 'stp', 'brep', 'ifc', 'dae', 'kmz'];
export const MATERIAL_MAPS = ['height', 'roughness', 'ambient'];
export const CASES_ON_MAIN_PAGE = 1;
export const REVIEWS_ON_MAIN_PAGE = 4;
export const MAX_COUNT_PARTS = 100;
export const MAX_TRIANGLES_FOR_OUTLINE = 5000;
export const ALL_VALUE = '__all__';

export const getDateNow = () => Date.now();

export const getDateBefore = (daysBefore = 7) => {
  const date = new Date();
  date.setDate(date.getDate() - daysBefore);
  return date;
};

export function getLastId<T extends IListItem>(values: T[]): number {
  return values.length > 0 ? Math.max(...values.map((item: T) => item.id ?? 0)) + 1 : 1;
}

export const isUrlSelected = (url: string): boolean => {
  const path = window.location.pathname;
  return ((url === '/' || url === '/en') && (path === '/' || path === '/en')) || (!(url === '/' || url === '/en') && path.indexOf(url) !== -1);
};

export const yandexReachGoal = (target: string): void => {
  // @ts-ignore
  window['ym']('88049236', 'reachGoal', target);
};