import axios from 'axios';

const site3dSite = window.location.hostname === 'localhost' || window.location.hostname === 'configurator-presite.site3d.ok' ? 'http://site3d.ok' : 'https://site3d.site';
const site3dConfiguratorSite = site3dSite.replace('site3d', 'configurator.site3d');
const site3dConfiguratorSiteBackend = `${site3dConfiguratorSite}/backend/`;

async function readJson(path)
{
  const response = await fetch(path);
  if (response.ok)
  {
    return response.json();
  }
}

function getJwtToken()
{
  const expireDate = new Date(localStorage.getItem('jwt-token-expire-date') ?? '');
  
  if (new Date() > expireDate)
  {
    return '';
  }
  
  return localStorage.getItem('jwt-token') ?? '';
}

async function backend(action, data = null, options = undefined)
{
  const optionsCur =
    {
      method: 'post',
      format: 'json',
      onUploadProgress: undefined,
      onDownloadProgress: undefined
    };
  
  if (options !== undefined)
  {
    Object.keys(options).forEach(key => optionsCur[key] = options[key]);
  }
  
  const url = action.indexOf('http') !== -1 ? action : `${site3dConfiguratorSiteBackend}backend.php`;
  
  const jwtToken = getJwtToken();
  
  const formData = new FormData();
  formData.append('action', action);
  formData.append('jwtToken', jwtToken);
  
  if (data !== null)
  {
    Object.keys(data).forEach(key =>
    {
      const item = data[key];
      if (item.blob !== undefined && item.blob !== null)
      {
        formData.append(key, item.blob, item.fileName);
      }
      else if (Array.isArray(item))
      {
        formData.append(key, item.join(','));
      }
      else
      {
        formData.append(key, typeof item === 'object' ? JSON.stringify(item) : item);
      }
    });
  }
  
  if (optionsCur.format === 'text')
  {
    return new Promise(resolve =>
    {
      fetch(url,
        {
          method: optionsCur.method,
          body: formData
        })
        .then(response => response.text())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }
  
  return new Promise(resolve =>
  {
    axios.request({
           method: optionsCur.method,
           responseType: optionsCur.format,
           url,
           data: formData,
           onUploadProgress: event =>
           {
             if (optionsCur.onUploadProgress === undefined)
             {
               return;
             }
        
             const percent = Math.floor(((event.loaded / event.total) * 100));
             optionsCur.onUploadProgress({percent});
           },
           onDownloadProgress: event =>
           {
             if (optionsCur.onDownloadProgress === undefined)
             {
               return;
             }
        
             const percent = event.total > 0 ? Math.floor(((event.loaded / event.total) * 100)) : -1;
             optionsCur.onDownloadProgress({percent});
           }
         })
         .then(result =>
         {
           const resultData = result.data;
           if (resultData.type === undefined || !resultData.data)
           {
             resolve({
               type: 'error',
               data: {
                 code: 'unknown_error',
                 message: '',
               }
             });
             return;
           }
           resolve(resultData);
         })
         .catch(error => resolve(error));
  });
}

async function getConfiguratorSettingsInit(typeId)
{
  if (typeId === '1')
  {
    return {};
  }
  
  return readJson(`${site3dSite}/configurator/planner/dataInit.json`);
}

async function getMaterials(options = undefined)
{
  const userId = options?.userId ?? 0;
  const lang = options?.lang ?? 'ru';
  
  const result = userId > 0 ?
    await backend('getUserMaterials', {userId, lang}) :
    await backend('getGlobalMaterials', {lang});
  
  const items = result.data?.value?.items;
  
  if (!(items && Array.isArray(items)))
  {
    return [];
  }
  
  return items;
}

export {
  site3dSite,
  site3dConfiguratorSite,
  site3dConfiguratorSiteBackend,
  backend,
  getConfiguratorSettingsInit,
  getMaterials
};
