import React from 'react';
import ReactModal from 'react-modal';
import Html from '../html/Html';

const Modal = ({
  caption,
  isOpen,
  onClose,
  content,
  vars,
  children,
  sort = 1,
  allowClose = true
}) =>
{
  return (
    <ReactModal overlayClassName="modalOverlay"
                className="modal"
                isOpen={isOpen}
                onRequestClose={allowClose ? onClose : null}
                style={{overlay: {zIndex: 10000 + sort}}}
    >
      <div className="modal__inner">
        {
          caption !== undefined &&
          <div className="modal__caption">{caption}</div>
        }
        <Html value={content} vars={vars} styles={['modal__content']}/>
        <div className="modal__buttons">{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
