import React from 'react';

export interface IconButtonProps {
  alias: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  title?: string,
  styles?: string[]
}

const IconButton = ({alias, onClick, title, styles}: IconButtonProps) => {
  const stylesCur = ['iconButton', `iconButton_${alias}`];
  
  if (styles)
    styles.forEach((item: string) => {
      stylesCur.push(item);
    });
  
  if (onClick === null)
    stylesCur.push('iconButton_notActive');
  
  return (
    <button className={stylesCur.join(' ')} onClick={onClick} title={title}/>
  );
};

export default IconButton;