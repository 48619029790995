import React from 'react';

export interface ButtonProps {
  caption: string,
  styles?: string[],
  styleModifiers?: string[],
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const Button = ({
  caption,
  styles,
  styleModifiers,
  onClick
}: ButtonProps) =>
{
  const stylesCur = ['button'];
  
  if (styles)
  {
    styles.forEach(item => stylesCur.push(item));
  }
  
  if (styleModifiers)
  {
    styleModifiers.forEach(item =>
    {
      stylesCur.push(`button_${item}`);
    });
  }
  
  return (
    <button className={stylesCur.join(' ')} onClick={onClick}>{caption}</button>
  );
};

export default Button;
