export function logMiddleware({dispatch})
{
  return function(next)
  {
    return function (action)
    {
      return next(action);
    }
  }
}
