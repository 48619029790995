import React from 'react';
import {FormattedMessage} from 'react-intl';
import Modal from './Modal';
import Button from '../html/Button';

const ModalAlert = ({
  isOpen,
  onClose,
  content,
  vars,
  allowClose = true
}) =>
{
  return (
    <Modal isOpen={isOpen} onClose={onClose} content={content} vars={vars} sort={3} allowClose={allowClose}>
      {
        allowClose &&
        <Button caption={<FormattedMessage id="phrase_close"/>}
                styles={['modal__button']}
                onClick={onClose}/>
      }
    </Modal>
  );
};

export default ModalAlert;
